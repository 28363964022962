import { template } from './modules/template.js';
import { form } from './modules/form.js';
import { eventHandler } from './modules/eventHandler.js';

export class Account {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	run() {

		form.done('edit-email', request => {

			if(request.response.success == true) {
				template.get({
					url: 'account/email',
					target: document.querySelector('form[name="edit-email"]').closest('[data-role=template-placeholder]')
				});
			}
		});

		form.done('edit-password', request => {

			if(request.response.success == true) {
				template.get({
					url: 'account/password',
					target: document.querySelector('form[name="edit-password"]').closest('[data-role=template-placeholder]')
				});
			}
		});

		form.done('delete-list', request => {

			if(request.response.success == true) {
				let ol = request.form.closest('ol');
				request.form.closest('li').remove();

				if(ol.querySelectorAll('li').length == 0) {

					template.get({
						url:'account/list/no_list',
						target: ol.closest('[data-role=template-placeholder]')
					});
				}
			}
		});


		const notification = document.querySelector('form[name=enable-notification] [name=notification]');
		eventHandler.setElementListener('change', 'account-notification', e => {

			notification.closest('div').classList.add('account-notification--loading');

		}, notification);

		template.done('account-list-delete', request => {

			if(request.element) {
				const list = request.element.closest('.list');
				if(list != null) {
					list.querySelector('form[name="delete-list"] input[name="list"]').value = list.getAttribute('data-list');
				}
			}
		});

		template.removed('cancel-delete-list', request => {

			if(request.element) {

				request.element.querySelector('.list-overlay').classList.remove('list-overlay--on');
				request.element.querySelector('.list-overlay').classList.add('list-overlay--off');

				request.element.querySelector('.list-popin').classList.remove('list-popin--on');
				request.element.querySelector('.list-popin').classList.add('list-popin--off');

				request.element.querySelector('.list-popin').addEventListener('animationend', (e) => {
					e.target.remove();
					request.element.querySelector('.list-overlay').remove();
				});
			}
		});
	}
}

export const account = new Account();