import { template } from '../modules/template.js';

const congratulations = [
   	"Bon choix !",
    "Pas mal !",
    "Bien vu !",
    "Oh c'est sympa !",
    "J'aime beaucoup cette idée.",
    "J'ai offert ça à mon épouse l'année dernière.",
    "J'ai offert ça à ma mère l'année dernière.",
    "J'ai offert ça à ma soeur une fois.",
    "Très bon choix.",
    "Oh ! J'adore.",
    "Très bonne idée !",
    "Ah oui, pas mal !",
    "Quelle bonne idée.",
    "Vous savez choisir des cadeaux.",
    "Ça c'est une bonne idée !",
    "J'aime beaucoup.",
    "Oh, j'aimerai m'offrir ça un jour.",
    "On m'a offert ça un jour.",
    "On m'a offert la même chose l'année dernière.",
    "Oh, j'ai ça chez moi !",
    "Joli.",
    "J'aime.",
    "J'aimerai que mes proches soient aussi généreux que vous.",
    "C'est une très belle liste que vous faites.",
    "J'aime beaucoup votre liste.",
    "Alors ça, j'adore !",
    "Votre liste est très sympa.",
    "Très bon choix.",
    "Très sympa.",
    "Bonne idée.",
    "Belle idée.",
    "J'aurai sans doute choisi la même chose à votre place."
];

export class Imp {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	constructor(options) {

		let defaults = {
			target: document.querySelector('[data-role=imp-content]'),
			container: document.querySelector('[data-role=imp-bubble]'),
			off: document.querySelectorAll('[data-action=imp-off]'),
			on: document.querySelectorAll('[data-action=imp-on]'),
			content: '',
		}

		this.congratulations = congratulations;

		this.options = Object.assign({}, defaults, options);

		if(!this.options.target) {
			return;
		}

		this.options.off.forEach(element => {
			element.addEventListener('click', e => {
				e.preventDefault();
				this.hide();
			});
		});

		this.options.on.forEach(element => {
			element.addEventListener('click', e => {
				e.preventDefault();
				this.show();
			});
		});

		if(this.options.target.querySelector('[data-show=false]') != null) {
			this.options.container.style.display = 'none';
		}
	}

	// ==============================================================================================================================================
	// methods
	// ==============================================================================================================================================

	set(key, value) {

		this.options[key] = value;
	}

	hide() {

		if(this.options.container.style.display != 'none') {
			this.options.container.classList.add('delete');
		}

		this.options.container.addEventListener('animationend', e => {

			if(e.currentTarget.classList.contains('delete')) {
				this.options.container.style.display = 'none';
				this.options.container.classList.remove('delete');
			}
		});
	}

	show() {

		if(!this.options.target.hasChildNodes()) {
			this.thinks();
		}

		this.options.container.style.display = null;
		this.options.container.classList.add('open');
	}

	thinks() {

		template.preload('imp', data => {
			return { list: this.options.list };
		});

		template.get({
			url: 'imp',
			key: 'imp',
			preload: true,
			target: this.options.target
		});
	}

	shutsUp() {

		this.options.target.innerHTML = null;
		this.hide();
	}

	says(message) {

		let messages = "";

		if(message != null) {
			if(Array.isArray(message)) {
				messages = message.join('<br />');
			}
			else {
				messages = message;
			}

			if(this.options.target != null) {
				this.options.target.innerHTML = messages;
			}
		}
		else {
			this.thinks();
		}

		this.show();
	}

	congrats() {

		this.says(this.congratulations[Math.floor(Math.random()*this.congratulations.length)]);
	}
}