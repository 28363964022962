export class Recents {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	constructor(options) {

		let defaults = {
			button: '.navbar-recent--toggle',
			container: '.navbar-lists',
			className: 'open'
		}

		this.options = Object.assign({}, defaults, options);
	}

	// ==============================================================================================================================================
	// exec function
	// ==============================================================================================================================================

	run() {
		let button = document.querySelector(this.options.button);

		button.addEventListener('click', e => {
			e.preventDefault();
			let element = document.querySelector(this.options.container);
			element.classList.toggle(this.options.className);
		});
	}
}

// ==================================================================================================================================================
// helper
// ==================================================================================================================================================

export function recents(options) {
	return new Recents(options);
}
